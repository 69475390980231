<style lang="scss">
.add-user-modal{
  &__roles{
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>

<template>
  <div class="add-user-modal">
    <section class="mb-2">
      <validation-provider
        #default="{ errors }"
        name="Name"
        rules="required"
      >
        <b-input-group>
          <b-form-input v-model="user.name" required class="mb-1" :state="errors.length > 0 ? false:null" placeholder="Enter name*" />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Email"
        rules="required|email"
      >
        <b-input-group>
          <b-form-input v-model="user.email" type="email" required class="mb-1" :state="errors.length > 0 ? false:null" placeholder="Enter email address*" />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Password"
        :rules="{ required: true, regex: /^(?=(?:.*[0-9]){1,})(?=(?:.*[a-z]){1,})(?=(?:.*[A-Z]){1,})(?=(?:.*[!@#$%^&*]){1,}).+$/, min: 8 }"
      >
        <b-input-group>
          <b-form-input v-model="user.password" :state="errors.length > 0 ? false:null" :type="showPassword ? 'text' : 'password'" placeholder="Enter password*" />
          <b-input-group-append>
            <b-button @click="showPassword = !showPassword">
              <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }} {{ errors.length > 0 ? "Password must contains numbers, special characters and upper and lower case letters" : '' }}</small>
        <small class="">Leave Blank to generate a random password.<br>The user will receive an invite email.</small>
      </validation-provider>

    </section>

    <p>Roles</p>

    <section class="d-flex flex-wrap add-user-modal__roles">
      <label v-for="role in roles" :key="role.id" class="d-flex col-4">
        <b-form-checkbox
          v-model="user.roles"
          :value="role.id"
        />
        {{ role.name }}
      </label>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="addUser()"
      >Add new user</b-button>
    </section>

  </div>
</template>

<script>
  import RolesService from '../../../services/RolesService';
  import UserService from '../../../services/UserService';

  export default {
    components: {},
    props: {},
    data() {
      return {
        showPassword: false,
        user: {
          name: '',
          email: '',
          password: '',
          roles: [],
        },
        roles: [],
      };
    },
    mounted() {
      this.getRoles();
    },
    methods: {
      addUser() {
        if (this.user.password < 1) {
          this.user.password = Math.random().toString(36).slice(-8);
        }
        UserService.createUser(this.user).then(() => {
          this.$toast.success(`Added user ${this.user.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.$emit('refresh', true);
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not add user, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }
          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      checkFormValid() {
        const emailReg = /\S+@\S+\.\S+/;
        const name = this.user.name.length > 0;
        const email = emailReg.test(this.user.email);

        return name && email;
      },
      getRoles() {
        RolesService.getRoles().then(res => {
          this.roles = res.data;
        }).catch(() => {
          this.$toast.error('Could not get roles, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
    },
  };
</script>
