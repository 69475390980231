<style lang="scss">
  .update-user-modal{
    &__roles{
      max-height: 200px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <div class="update-user-modal">
    <section class="mb-2">
      <b-input-group class="mb-2">
        <b-form-input v-model="password.new_password" required :type="showPassword ? 'text' : 'password'" placeholder="Enter new password*" />
        <b-input-group-append>
          <b-button @click="showPassword = !showPassword">
            <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" />
          </b-button>

        </b-input-group-append>
      </b-input-group>

      <b-input-group>
        <b-form-input v-model="password.new_password_confirm" required :type="showPassword2 ? 'text' : 'password'" placeholder="Re-enter new password*" />
        <b-input-group-append>
          <b-button @click="showPassword2 = !showPassword2">
            <i class="fas" :class="showPassword2 ? 'fa-eye' : 'fa-eye-slash'" />
          </b-button>

        </b-input-group-append>
      </b-input-group>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="updateUserPassword()"
      >Change password</b-button>
    </section>

  </div>
</template>

<script>
  import UserService from '../../../services/UserService';

  export default {
    components: {},
    props: {
      user: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        showPassword: false,
        showPassword2: false,
        password: {
          new_password: '',
          new_password_confirm: '',
        },
      };
    },
    mounted() {},
    methods: {
      updateUserPassword() {
        UserService.updateUserPassword(this.user.uid, this.password).then(() => {
          this.$toast.success('Updated user password Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(() => {
          this.$toast.error('Could not update user password, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        const password = this.password.new_password.length > 7;
        const password2 = this.password.new_password_confirm === this.password.new_password;

        return password && password2;
      },
    },
  };
</script>
